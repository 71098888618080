.site-footer {
  background-color: $reversed-background-color;
  color: $reversed-text-color;
  padding: 2em 1em;

  .footer-wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin: 0 auto;
    max-width: 35em;
  }

  .footer-pane {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    grid-column: 1 / 6;
    justify-content: space-between;
    margin-bottom: 1.5em;
  }

  .footer-item {
    a {
      position: relative;
    }

    > a {
      font-weight: bold;
    }

    .active::before {
      content: "»";
      left: -1em;
      position: absolute;
    }

    li {
      margin: 0.5em 0;
    }

    .footer-submenu {
      list-style-type: none;
    }
  }

  @include maxsize(540px) {
    .footer-wrapper {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 2em;
    }

    .footer-pane {
      display: flex;
      flex-direction: column;
      grid-column: 1 / 2;
      margin-bottom: 0;
      min-height: 7em;
    }
  }
}
