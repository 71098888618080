@charset "utf-8";
@media all                        {html {font-size: 16px;}}
@media all and (min-width:480px)  {html {font-size: 17px;}}
@media all and (min-width:600px)  {html {font-size: 18px;}}
@media all and (min-width:960px)  {html {font-size: 19px;}}
@media all and (min-width:1200px) {html {font-size: 20px;}}
@media all and (min-width:1500px) {html {font-size: 21px;}}

$head-stack: Spartan, "League Spartan", Avenir, Futura, sans-serif !default;
$body-stack: "Fira Sans", "Gill Sans", "Lucida Grande", Roboto, sans-serif !default;
$code-stack: "Fira Code", "Roboto Mono", Consolas, monospace !default;
$text-color: #1d1f21 !default;
$background-color: #fffcfa !default;
$reversed-text-color: #fffcfa !default;
$reversed-background-color: #1d1f21 !default;

$link-color: #b54a81 !default;
$reversed-link-color: #d086ad !default;
$secondary-color: #808080 !default;
$tertiary-color: #b0b0b0 !default;

$feed-color: #7e335a !default;
$feed-hover-color: #a1306b !default;
$feed-text-color: #fdf8ef !default;

@mixin maxsize($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin minsize($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@import "base", "clsfix", "feed", "footer", "header", "home", "highlight";
