.feed {
  .feed-search {
    display: grid;
    grid-gap: 1rem;
    grid-template: 1fr / 1fr 6em 5.5em;
    margin-bottom: 1em;

    .search {
      background: #f0f0f0;
      border: none;
      border-bottom: 2px solid #f0f0f0;
      border-radius: 0;
      box-sizing: border-box;
      font: inherit;
      font-size: 1.15em;
      height: 2em;
      padding: 1.5em 1em;
      vertical-align: middle;
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    button {
      min-width: 0;
    }

    @include maxsize(600px) {
      grid-template: 1fr 2em / 1fr 1fr;

      .search {
        grid-column: 1 / 3;
      }
    }
  }

  .feed-checkboxes {
    grid-template-columns: repeat(auto-fill, minmax(11em, 1fr));
    grid-gap: 1rem;
    margin-bottom: 1rem;

    .feed-subcheckboxes {
      list-style-type: none;
      margin-left: 1.5em;
      margin-right: 1em;

      li {
        margin-bottom: 0;
      }
    }
  }

  .feed-tags-list {
    flex-flow: row wrap;
    margin-bottom: 1em;

    span, a {
      margin-right: 0.5em;
      margin-bottom: 0.25em;
    }
  }

  .feed-search label, input[type='checkbox'] {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }

  input[type='checkbox'] {
    & + label {
      cursor: pointer;
      display: block;
      position: relative;

      &::before {
        background: $background-color;
        border: 2px solid $secondary-color;
        content: "";
        display: inline-block;
        height: 1em;
        margin-right: 0.5em;
        margin-bottom: 0.2em;
        position: relative;
        vertical-align: middle;
        width: 1em;
      }
    }

    &:checked, &:hover, &:checked:hover {
      & + label::before {
        background: $feed-color;
        border-color: $feed-color;
      }

      & + label::after {
        content: '';
        position: absolute;
        top: 0.45em;
        left: 0.25em;
        border-left: 2px solid $reversed-text-color;
        border-bottom: 2px solid $reversed-text-color;
        height: 0.2em;
        width: 0.6em;
        transform: rotate(-45deg);
      }
    }

    &:hover, &:focus {
      & + label::before {
        background: $background-color;
      }

      & + label::after {
        border-color: $feed-color;
      }
    }

    &:checked:hover, &:checked:focus {
      & + label::before {
        background: $feed-hover-color;
        border-color: $feed-hover-color;
      }

      & + label::after {
        border-color: $reversed-text-color;
      }
    }

    &:focus {
      & + label::before {
        outline: $text-color auto 1px;
      }
    }
  }

  .feed-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(14em, 1fr));
    grid-gap: 1rem;
    margin-bottom: 1rem;
    width: 100%;

    a:hover, a:focus {
      font-weight: normal;

      .feed-post {
        background: $feed-hover-color;
      }
    }
  }

  .feed-post {
    background: $feed-color;
    box-sizing: border-box;
    color: $feed-text-color;
    height: 10em;
    padding: 1em;
    position: relative;
    width: 100%;

    .feed-date {
      opacity: 0.8;
      font: 0.8em $head-stack;
      text-transform: uppercase;
      letter-spacing: 0.075em;
    }

    .feed-title {
      font: bold 1.15em $body-stack;
      letter-spacing: normal;
      line-height: 1.2;
      margin-top: 0;
      text-transform: none;
    }

    .feed-categories {
      bottom: 0.75em;
      opacity: 0.9;
      position: absolute;
    }

    .feed-tags {
      display: none;
    }
  }

  .feed-pagination {
    margin-bottom: 1em;
    text-align: center;
  }

  .pagination {
    &, li {
      display: inline-block;
    }

    .page {
      -webkit-box-shadow: none;
      border-radius: 1.5em;
      border: 2px solid black;
      box-shadow: none;
      box-sizing: border-box;
      display: block;
      font: 0.8em $head-stack;
      height: 2em;
      margin: 0 0.4em;
      padding-top: 0.45em;
      text-align: center;
      width: 2em;
    }

    .page:hover, li.active .page {
      background: $reversed-background-color;
      color: $reversed-text-color;
    }

    li.disabled .page {
      border: none;
      cursor: initial;

      &:hover {
        background: inherit;
        color: inherit;
      }
    }
  }
}

.archive {
  margin: 0 auto;
  max-width: 44em;
  padding: 2em 1em;

  .feed-list {
    min-height: 21em;

    @include maxsize(827px) {
      min-height: 32em;
    }

    @include maxsize(526px) {
      min-height: 65em;
    }
  }
}
