.site-header {
  background-color: $reversed-background-color;
  box-sizing: border-box;
  color: $reversed-text-color;
  left: 0;
  padding: 1em;
  position: absolute;
  top: 0;
  width: 100%;

  .site-title {
    display: inline-block;
    font: 1.5em $head-stack;
    margin-right: 1rem;
  }
}

.site-nav {
  display: inline-block;

  .nav-menu {
    list-style-type: none;

    > li {
      display: inline-block;
      font: 1.2em $head-stack;
      margin-right: 1rem;
      position: relative;
      text-transform: lowercase;

      .nav-submenu {
        background-color: $reversed-background-color;
        display: none;
        left: -1.5em;
        list-style-type: none;
        padding: 0.5em;
        padding-top: 0;
        position: absolute;
        top: 1.5em;
        z-index: 1;

        > li {
          font-size: 1rem;
          margin: 1em;
        }

        .active::before {
          content: "»";
          left: 0.6em;
          position: absolute;
        }
      }

      .nav-submenu::before {
        content: "";
        display: block;
        height: 0.5em;
        position: absolute;
        top: -0.5em;
        width: 100%;
        z-index: 1;
      }

      &:hover .nav-submenu {
        display: block;
      }
    }
  }

  .nav-trigger, .nav-menu-button {
    display: none;
  }

  @media screen and (max-width: 720px), screen and (hover: none) {
    bottom: 1em;
    position: absolute;
    right: 1em;

    label[for="nav-trigger"] {
      border: 1px solid $reversed-text-color;
      color: $reversed-text-color;
      cursor: pointer;
      display: block;
      padding: 0.5em 0.9em 0.25em 1em;
    }

    input:checked ~ label[for="nav-trigger"] {
      background: $background-color;
      color: $text-color;
    }

    .nav-menu-button {
      background: none;
      color: inherit;
      display: block;
      min-width: 0;
      padding: 0;
      text-transform: uppercase;
    }

    input ~ .nav-menu {
      display: none;
    }

    input:checked ~ .nav-menu {
      background: $reversed-background-color;
      box-sizing: border-box;
      display: block;
      padding-left: calc((100vw - 9em)/2);
      padding-bottom: 1em;
      position: absolute;
      right: -1em;
      top: 2em;
      width: 100vw;
      z-index: 1;

      > li {
        display: block;
        margin: 0.75em 1em;

        .nav-submenu {
          display: block;
          padding: 0;
          position: static;

          li {
            font-size: 1.2rem;
            margin: 0.75em 1em;
          }

          .active::before {
            left: 0;
          }
        }
      }

      .active::before {
        content: "»";
        left: -1em;
        position: absolute;
      }
    }
  }
}
