.cls-fix {
  position: relative;
  width: 100%;
  height: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.cls-fix.home-hero {
  padding-top: calc(0.378 * 100%);
  
  @media (max-aspect-ratio: 4/3) {
    padding-top: calc(0.536 * 100%);
  }
  @media (max-aspect-ratio: 3/4) {
    padding-top: calc(100%);
  }
  @media (max-aspect-ratio: 9/16) {
    padding-top: calc(4/3 * 100%);
  }
}

.cls-fix.about {
  padding-top: calc(2/3 * 100%);
  
  @media (max-aspect-ratio: 3/4) {
    padding-top: calc(100%);
  }
}

.cls-fix.math, .cls-fix.code {
  padding-top: calc(0.4 * 100%);

  @media (max-width: 520px) {
    padding-top: calc(2/3 * 100%);
  }
}
