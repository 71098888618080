// reset

body, h1, h2, h3, h4, h5, h6, p, blockquote, pre, hr, dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

main {
  display: block;
}

// typography

body {
  background-color: $background-color;
  color: $text-color;
  font-family: $body-stack;
  line-height: 1.5;
}

p, hr {
  margin-bottom: 1em;
}

li {
  margin-bottom: 0.5em;
}

a {
  color: inherit;
  text-decoration: none;
}

.site-header a:hover, .site-footer a:hover {
  -webkit-box-shadow: 0 -2px $reversed-link-color inset;
  box-shadow: 0 -2px $reversed-link-color inset;
}

.page-content a {
  -webkit-box-shadow: 0 -2px $link-color inset;
  box-shadow: 0 -2px $link-color inset;

  &:hover {
    color: $link-color;
  }
}

a:focus {
  font-weight: bold;
}

h1, h2, h3 {
  font-family: $head-stack;
  letter-spacing: -0.05em;
  margin-bottom: 0.5rem;
  text-transform: lowercase;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.75em;
  margin-top: 2rem;
}

h3 {
  font-size: 1.5em;
  margin-top: 1.5rem;
}

h4 {
  font-size: 1em;
}

.anchor {
  border: none;
  height: 2em;
  min-width: 0;
  margin: 0 0.5em;
  padding: 0.1em 0.25em 0;
  vertical-align: -0.25em;

  svg {
    fill: $tertiary-color;
    height: 1.5em;
    width: 1.5em;
  }

  .tooltip {
    background: $reversed-background-color;
    display: none;
    font-family: $body-stack;
    padding: 0.4rem;
    letter-spacing: 0;
    position: absolute;
    text-transform: none;
  }

  &:hover, &:focus, &.active {
    background: transparent;
    transform: none;

    svg {
      fill: $link-color;
    }

    .tooltip {
      display: block;
    }
  }
}

button {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 2px solid $text-color;
  color: $text-color;
  cursor: pointer;
  display: inline-block;
  font: 0.8em $head-stack;
  letter-spacing: 0.075em;
  line-height: 1;
  margin: 0;
  min-width: 10em;
  padding: 0.75em 1em 0.45em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  &:hover, &:focus, &.active {
    background: $reversed-background-color;
    color: $reversed-text-color;
  }

  &:active {
    transform: scale(0.99);
  }
}

img {
  height: auto;
  width: 100%;
}

td, th {
  padding-right: 1em;
  padding-bottom: 0.5em;
}

th {
  text-align: initial;
}

code, pre {
  font-family: $code-stack;
}

// content format

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.skip-link {
  background: $reversed-background-color;
  color: $reversed-text-color;
  left: 0;
  padding: 1em;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  z-index: 2;
}

.skip-link:focus {
  transform: translateY(0%);
}

.page-content {
  margin-top: 3.75em;
}

.post {
  margin: 0 auto;
  max-width: 35em;
  padding: 2em 1em;

  .page-hero {
    margin-bottom: 1em;
    width: 100%;
  }

  ul, ol {
    padding-left: 1em;
    margin-bottom: 1em;
  }

  li {
    margin: 0;
  }

  table {
    margin: 1em auto;
  }

  .post-header {
    margin-bottom: 0;
  }

  .post-date {
    color: $secondary-color;
  }
}

.postlist {
  padding: 0.2em 0;

  .postlist-title {
    font-family: inherit;
    letter-spacing: 0;
    line-height: 1;
    margin: -0.1em 0 0.2em;
    text-transform: none;

    a {
      font-size: 1rem;
    }
  }

  .postlist-post:last-child p:last-child {
    margin-bottom: 0;
  }
}

.page-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(14em, 1fr));
  grid-gap: 0.5rem 1.5rem;
  margin-top: 1rem;

  div {
    a {
      -webkit-box-shadow: none;
      box-shadow: none;

      button {
        margin-bottom: 1rem;
        width: 100%;
      }
    }
  }
}

.table-container {
  overflow-x: auto;

  table {
    min-width: 35em;
  }
}

.contact {
  td:first-child {
    padding: 0.25em;
    padding-left: 0;

    svg {
      height: 1.5em;
    }
  }
}

.archive {
  .feed-list a {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
