.home {
  .home-hero {
    h1 {
      background-color: $reversed-background-color;
      bottom: -2rem;
      color: $reversed-text-color;
      font-size: 2em;
      left: calc((100% - 35rem) / 2 - 1.25rem);
      padding: 1rem;
      padding-bottom: 0.25rem;
      position: absolute;
      text-transform: none;

      @include maxsize(680px) {
        left: -0.25rem;
      }
    }
  }

  .post {
    padding-top: 2.5em;
  }

  @media screen and (min-aspect-ratio: 16/9) {
    .home-hero h1 {
      bottom: 1rem;
    }

    .post {
      padding-top: 1em;
    }
  }

  .button-row {
    display: grid;
    grid-gap: 1rem;
    grid-template: 1fr / 1fr 1fr 1fr;

    button {
      width: 100%;
    }
  }

  @include maxsize(542px) {
    .button-row {
      grid-template: 1fr 1fr / 1fr 1fr;

      a:first-child {
        grid-column: 1 / 3;
      }
    }
  }

  .feed {
    .feed-list {
      min-height: 21em;

      @include maxsize(542px) {
        min-height: 43em;
      }
    }

    .feed-check-item {
      margin-right: 0.5em;
    }

    .feed-subcheckboxes,
    .pagination {
      display: none;
    }
  }
}
